import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import Flickity from 'react-flickity-component';
import { Trans } from '@lingui/react';
import { getFormattedPrice } from 'features/utils/price';
import { getLayoutFillNextImage } from 'features/utils/images';
import PrimaryButton from 'ui/components/buttons/PrimaryButton';
import SecondaryButton from 'ui/components/buttons/SecondaryButton';
import LocalizedLink from 'features/routing/LocalizedLink';
import { ListingSearchQueryResult, ShowerOption, ToiletOption, TransmissionType } from 'api/src';
import dayjs from 'dayjs';
import { mediaMdMax, mediaMdMin, mediaSmMax, mediaSmMin } from 'ui/utils/breakpoints';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { Body, staticHeadlineTextStyles } from 'ui/components/primitives/typography/CustomTypography';
import { Typography } from 'ui/primitives/typography';
import { Flex, FlexAlignCenter, FlexCol } from 'ui/primitives/flex';
import CompassIcon from 'ui/icons/ic_compass.svg';
import { routes } from '~/src/utils/routing';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import { useSendBookingDataIntent } from '~/src/components/carDetail/booking/utils';
import { getCarCalculationById } from '~/src/api/cars';
import { convertToSlug } from '~/src/components/carDetail/utils';
import { hideDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { setOfferChosenVehicle, setOfferStep } from '~/src/redux/actions/offer';
import { offerStepSelector } from '~/src/redux/selectors/offer';
import { useTypedSelector } from '~/src/redux/store';
import { screenSelector } from 'features/redux/selectors/screen';
import useBreakpoints, { SMALL_SCREEN_QUERY } from 'features/hooks/useBreakpoints';
import { t } from '@lingui/macro';
import { HiddenOnDesktop, HiddenOnMobile } from 'ui/primitives/hidden';
import { getLocalizedTitle } from '~/src/utils/translations';
import { trackProductClickDataLayer } from '~/src/utils/dataLayer';
import { setSearchDates } from 'features/redux/actions/search';
import { isValidBookingDate } from '~/src/components/search/utils';
import { useLocalizedRouter } from '~/src/components/LocalizedLink';

const flickityOptions = {
  initialIndex: 0,
  contain: true,
  pageDots: false,
  arrowShape: '',
  draggable: false,
  fullscreen: true,
};

interface CarItemProps {
  carDataProp: ListingSearchQueryResult;
  isModal?: boolean;
  unsetWidth?: boolean;
  hidePrice?: boolean;
  forceVertical?: boolean;
  filterDate?: {
    startDate: string;
    endDate: string;
  };
  openInNewWindow?: boolean;
  firstImageFetchPriority?: 'high' | 'low';
  dateOffset?: number;
}

export const caravanSearchSizes =
  '(min-width: 1140px) 390px, (min-width: 1024px) calc((100vw - 64px) * .285), (min-width: 601px) calc((100vw - 32px) * .485), calc(100vw - 32px)';

const SERVICE_FEE = 99;

function CarItem({
  carDataProp,
  isModal = false,
  unsetWidth,
  forceVertical = false,
  hidePrice = false,
  openInNewWindow = false,
  filterDate = undefined,
  firstImageFetchPriority = undefined,
  dateOffset = 0,
}: CarItemProps) {
  const carouselRef = useRef<Flickity>();
  const settings = useSelector(settingsSelector);
  const dispatch = useDispatch();
  const router = useLocalizedRouter();

  const offerStep = useTypedSelector(offerStepSelector);
  const screen = useTypedSelector(screenSelector);
  const [smallScreen] = useBreakpoints(SMALL_SCREEN_QUERY);

  const startDate = filterDate && filterDate.startDate ? dayjs(filterDate.startDate).add(dateOffset, 'day') : null;
  const endDate = filterDate && filterDate.endDate ? dayjs(filterDate.endDate).add(dateOffset, 'day') : null;

  const getCarItemQuery = () => {
    if (!filterDate) return {};

    return {
      dateFrom: startDate.format('YYYY-MM-DD'),
      dateTo: endDate.format('YYYY-MM-DD'),
    };
  };

  const isDateSet = !!filterDate?.startDate && !!filterDate?.endDate;

  const sendBookingDataIntent = useSendBookingDataIntent(true);

  const createBooking = async () => {
    if (!isValidBookingDate(startDate.toDate(), endDate.toDate())) {
      dispatch(setSearchDates(null));
      router.localizedReplace(routes.search, {});
      return;
    }
    const dates = getCarItemQuery();
    try {
      const response = await getCarCalculationById(carDataProp.id, {
        passengers: 0,
        start: dates.dateFrom,
        end: dates.dateTo,
      });

      await sendBookingDataIntent(carDataProp, carDataProp.instantBookable, null, {
        expectedTotal: response.data.original.total.total,
        bookingDates: {
          dateFrom: dayjs(dates.dateFrom),
          dateTo: dayjs(dates.dateTo),
        },
        calculationData: response.data,
        ecommerceArea: 'search results',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getMultipleDays = () => {
    if (!filterDate) return -1;

    const daysLength = dayjs(endDate).diff(dayjs(startDate), 'days');
    const bookingLength = daysLength + (carDataProp.returnDayIncluded ? 0 : 1);

    // must always be at least 1
    if (bookingLength <= 0) return 1;

    return bookingLength;
  };

  const isModalBox = isModal ? 'isModal' : '';
  const unsetWidthClass = unsetWidth ? 'unsetWidth' : '';
  const hidePriceClass = hidePrice ? 'hidePriceBar' : '';

  const carBoxClasses = ` ${isModalBox} ${unsetWidthClass} ${hidePriceClass} carItemFWC`;

  const selectCar = () => {
    dispatch(setOfferChosenVehicle(carDataProp));
    hideDesktopOrPhoneModal(screen, dispatch);

    if (offerStep < 2) {
      dispatch(setOfferStep(2));
    }
  };

  const getPriceSection = () => (
    <CarBoxContenFooterDesktop forceVertical={forceVertical}>
      <CarBoxPriceSection data-has-date={isDateSet} forceVertical={forceVertical}>
        <FromPricing>
          {!isDateSet && (
            <span className="from">
              <HiddenOnMobile>
                <Trans id="carBox.content.footer.from" message="from" />{' '}
              </HiddenOnMobile>
              <HiddenOnDesktop>
                <Trans id="carBox.fwc.content.footer.priceFrom" message="Price from" />{' '}
              </HiddenOnDesktop>
            </span>
          )}
          <PriceWrap>
            <span className="price">
              {getFormattedPrice(
                isDateSet
                  ? (carDataProp?.totalPrice?.total - SERVICE_FEE) / getMultipleDays()
                  : carDataProp?.minPrice?.total,
                settings
              )}
            </span>
            <span className="day">
              {`/${
                carDataProp.returnDayIncluded
                  ? t({ id: 'label.night', message: 'night' })
                  : t({ id: 'label.day', message: 'day' })
              }`}
            </span>
          </PriceWrap>
        </FromPricing>

        <FlexCol>
          <CarBoxPrice data-small-price={true}>
            <Trans id="labels.serviceFeeIncluded" message="plus 99€ service fee" />{' '}
          </CarBoxPrice>
          {isDateSet && (
            <CarBoxPrice>
              <span className="bigPrice">{getFormattedPrice(carDataProp?.totalPrice?.total, settings, false, 2)}</span>
              {getMultipleDays() > 0 && (
                <span>
                  {' '}
                  <Trans
                    id="carItem.fwc.totalPricingNew"
                    message="for {days} {pricingType}"
                    values={{
                      days: getMultipleDays(),
                      pricingType: carDataProp.returnDayIncluded
                        ? t({ id: 'label.nights', message: 'nights' })
                        : t({ id: 'label.days', message: 'days' }),
                    }}
                  />
                </span>
              )}
            </CarBoxPrice>
          )}
        </FlexCol>
      </CarBoxPriceSection>

      {isModal ? (
        <CarBoxCTASection>
          <PrimaryButton dataTestId="choose-car-offer" onClick={selectCar} smallerPadding={true}>
            <Trans id="button.labels.chooseCaravan" message="Select campervan" />
          </PrimaryButton>
          <LocalizedLink
            href={routes.carDetail}
            linkParams={{
              id: carDataProp.id,
              name: carDataProp?.urlSlug || convertToSlug(carDataProp.title),
            }}
            query={getCarItemQuery()}
            prefetch={false}
            newWindow={true}
          >
            <SecondaryButton smallerPadding={true} as="div">
              <Trans id="camperItem.fwc.seeDetails" message="See details" />
            </SecondaryButton>
          </LocalizedLink>
        </CarBoxCTASection>
      ) : (
        <CarBoxCTASection>
          <SecondaryButton smallerPadding={smallScreen}>
            <Trans id="camperItem.fwc.seeDetails" message="See details" />
          </SecondaryButton>

          {getMultipleDays() > 0 && (
            <PrimaryButton smallerPadding={smallScreen} className="createBookingCTA" onClick={createBooking}>
              <Trans id="camperItem.fwc.bookCamper" message="Book camper" />
            </PrimaryButton>
          )}
        </CarBoxCTASection>
      )}
    </CarBoxContenFooterDesktop>
  );
  return (
    <LocalizedLink
      newWindow={openInNewWindow}
      href={routes.carDetail}
      linkParams={{
        id: carDataProp.id,
        name: carDataProp?.urlSlug,
      }}
      onClick={(e) => {
        if (
          ['stationLink', 'flickity-button', 'createBookingCTA'].filter(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (name) => e.target.getAttribute('class')?.includes(name)
          ).length > 0
        ) {
          e.preventDefault();
          return;
        }
        trackProductClickDataLayer(carDataProp);
      }}
      query={getCarItemQuery()}
      prefetch={false}
      passHref={!isModal}
      disabled={isModal}
    >
      <CarBox forceVertical={forceVertical} data-testid="car-item" className={carBoxClasses}>
        <CarBoxImageWrapper forceVertical={forceVertical}>
          {/* {carDataProp?.badges?.length > 0 && (
            <CarItemBadgesWrapper>
              {carDataProp?.badges?.map((t) => <CarItemBadge key={t.type} type={t.type} />)}
            </CarItemBadgesWrapper>
          )} */}
          <Flickity
            flickityRef={(c: Flickity) => {
              carouselRef.current = c;
            }}
            className="carousel"
            elementType="div"
            options={flickityOptions}
            disableImagesLoaded={true}
          >
            {carDataProp.pictures?.length === 0 && (
              <CarBoxImageWrapper forceVertical={forceVertical}>
                <img src="/static/images/vectors/placeholder.svg" alt="" />
              </CarBoxImageWrapper>
            )}
            {carDataProp?.pictures?.map((picture, index) => (
              <CarBoxImageWrapper forceVertical={forceVertical} key={picture.guid}>
                {getLayoutFillNextImage(
                  picture,
                  'caravan image',
                  undefined,
                  caravanSearchSizes,
                  undefined,
                  index === 0 ? firstImageFetchPriority : undefined
                )}
              </CarBoxImageWrapper>
            ))}
          </Flickity>
        </CarBoxImageWrapper>

        <CarBoxContent forceVertical={forceVertical}>
          <CarBoxContentHead>
            <CarBoxContentHeadLeft>
              <Body $size="xlBold">
                {getLocalizedTitle(carDataProp.translations, settings.language) || carDataProp.title}
              </Body>

              {isDateSet && (
                <Flex $gap={tokens.spacing.spacingS} $my={tokens.spacing.spacingXs}>
                  <Body
                    $size="m"
                    $tDecoration={dateOffset ? 'line-through !important' : undefined}
                    $background={tokens.color.items.fillIconLight}
                    $px={tokens.spacing.spacingXs}
                    $lHeight="1"
                    $borderRadius={tokens.radius.radiusM}
                    $color={tokens.color.text.textSecondary}
                  >
                    {dayjs(filterDate.startDate).format('DD.MM.')} - {dayjs(filterDate.endDate).format('DD.MM.')}
                  </Body>
                  {!!dateOffset && (
                    <Body
                      $size="m"
                      $background={tokens.color.items.calendarActive}
                      $px={tokens.spacing.spacingXs}
                      $lHeight="1"
                      $borderRadius={tokens.radius.radiusM}
                      $color={tokens.color.text.textSecondary}
                    >
                      {startDate.format('DD.MM.')} - {endDate.format('DD.MM.')}
                    </Body>
                  )}
                </Flex>
              )}
              <FlexAlignCenter className="stationInfo" $gap={tokens.spacing.spacingS}>
                <AvailableAt>
                  <Trans
                    id="carItem.fwc.availableAt"
                    message="Available at <0/> station"
                    components={{
                      0: (
                        <Typography
                          $fWeight={600}
                          $cursor="pointer"
                          $fSize="12px"
                          $mdMin={{
                            $fSize: '14px',
                          }}
                          as="span"
                          className="stationLink"
                          onClick={(e) => {
                            // e.preventDefault();
                            // alert('Set filter for this station'); TODO
                          }}
                          $color={tokens.color.text.textLink}
                        >
                          {carDataProp.stationName}
                        </Typography>
                      ),
                    }}
                  />
                </AvailableAt>
                <FlexAlignCenter
                  $gap={tokens.spacing.spacingXs}
                  $px={tokens.spacing.spacingXxs}
                  $background={tokens.color.backgrounds.surfaceContrast}
                  $borderRadius={tokens.radius.radiusM}
                >
                  <CompassIcon height={12} />
                  <Body $size="s">{carDataProp.distance}&nbsp;km</Body>
                </FlexAlignCenter>
              </FlexAlignCenter>
            </CarBoxContentHeadLeft>
          </CarBoxContentHead>
          <CarBoxContentBody className="carBadges">
            <CarBoxEquipments>
              {(carDataProp?.passengersCapacity || 0) > 0 && (
                <CarBoxEquipmentsItem>
                  <Icon className="icon icon-seats" />
                  <CarBoxEquipmentsItemCount>{carDataProp.passengersCapacity}</CarBoxEquipmentsItemCount>
                </CarBoxEquipmentsItem>
              )}

              <CarBoxEquipmentsItem>
                <Icon className="icon icon-bed" />
                <CarBoxEquipmentsItemCount>{carDataProp.sleepCapacity}</CarBoxEquipmentsItemCount>
              </CarBoxEquipmentsItem>

              {carDataProp.transmissionType === TransmissionType.Automatic && (
                <CarBoxEquipmentsItem>
                  <Icon className="icon icon-pnd" />
                  <CarBoxEquipmentsItemCount>Aut.</CarBoxEquipmentsItemCount>
                </CarBoxEquipmentsItem>
              )}
              {carDataProp.transmissionType === TransmissionType.Manual && (
                <CarBoxEquipmentsItem>
                  <Icon className="icon-icon-specification-manual" />
                  <CarBoxEquipmentsItemCount>Man.</CarBoxEquipmentsItemCount>
                </CarBoxEquipmentsItem>
              )}

              {carDataProp.toilet !== ToiletOption.NoToilet && carDataProp.toilet !== ToiletOption.NotSet && (
                <CarBoxEquipmentsItem>
                  <Icon className="icon icon-wc" />
                </CarBoxEquipmentsItem>
              )}

              {carDataProp.shower !== ShowerOption.NoShower && carDataProp.shower !== ShowerOption.NotSet && (
                <CarBoxEquipmentsItem>
                  <Icon className="icon icon-shower" />
                </CarBoxEquipmentsItem>
              )}
            </CarBoxEquipments>
          </CarBoxContentBody>
        </CarBoxContent>
        {getPriceSection()}
      </CarBox>
    </LocalizedLink>
  );
}

export default CarItem;

const CarBoxContenFooterDesktop = styled.div<{ forceVertical: boolean }>`
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 ${resolveThemeValue(tokens.spacing.spacingL)} ${resolveThemeValue(tokens.spacing.spacingL)};
  padding-top: ${resolveThemeValue(tokens.spacing.spacingM)};
  border-top: 1px solid ${resolveThemeValue(tokens.color.border.border)};

  ${({ forceVertical }) =>
    !forceVertical &&
    css`
      ${mediaSmMin} {
        width: 100%;
        border: none;
        max-width: 296px;
        padding-top: 0;
        margin: 32px 32px 32px 0;
      }
    `}
`;

const CarBox = styled.div<{ forceVertical: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: 284px;
  height: fit-content;
  max-height: fit-content;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  max-width: 1200px;
  width: 100%;

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(31, 34, 68, 0.12);
  }

  .carousel,
  .flickity-slider {
    height: 100%;
    width: 100%;
  }

  &.hidePriceBar {
    ${CarBoxContenFooterDesktop} {
      display: none;
    }
  }

  border: 1px solid ${resolveThemeValue(tokens.color.border.border)};

  .flickity-prev-next-button {
    border-radius: 99px;
    background: rgba(0, 22, 41, 0.4);
    padding: 18px;

    &:before {
      content: '';
      background-image: url('/static/images/vectors/flickity_arrow_white.svg');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      left: 12px;
      width: 20px;
      height: 20px;
      position: absolute;
      top: calc(50% - 12px);
      z-index: 0;
    }

    /* position: relative; */
  }

  .flickity-prev-next-button.previous {
    left: 8px;
  }

  .flickity-prev-next-button.next {
    right: 8px;

    &:before {
      transform: rotate(180deg);
    }
  }

  ${mediaSmMax} {
    flex-direction: column;
  }

  ${({ forceVertical }) =>
    forceVertical &&
    css`
      flex-direction: column;
    `}
`;

const CarBoxImageWrapper = styled.div<{ forceVertical: boolean }>`
  width: 488px;
  height: 284px;

  position: relative;
  background-color: #d8d8d8;
  overflow: hidden;
  display: flex;

  img {
    object-fit: cover;
  }

  ${mediaSmMax} {
    height: 220px;
    width: 100%;
  }

  ${({ forceVertical }) =>
    forceVertical &&
    css`
      height: 220px;
      width: 100%;
    `}
`;

const CarBoxContent = styled.div<{ forceVertical: boolean }>`
  padding: ${resolveThemeValue(tokens.spacing.spacing4Xl)};
  width: 100%;
  max-width: 448px;

  ${mediaSmMax} {
    padding: ${resolveThemeValue(tokens.spacing.spacingM)} ${resolveThemeValue(tokens.spacing.spacingL)};
    max-width: unset;
  }

  ${({ forceVertical }) =>
    forceVertical &&
    css`
      padding: ${resolveThemeValue(tokens.spacing.spacingM)} ${resolveThemeValue(tokens.spacing.spacingL)};
      max-width: unset;
    `}
`;

const CarBoxContentHead = styled.div`
  display: flex;
  padding-bottom: ${resolveThemeValue(tokens.spacing.spacingM)};
  ${mediaSmMin} {
    padding-bottom: ${resolveThemeValue(tokens.spacing.spacing2Xl)};
  }
`;

const CarBoxContentHeadLeft = styled.div`
  max-width: 384px;
  width: 100%;
  overflow: hidden;

  ${mediaSmMax} {
    max-width: unset;
  }
`;

const AvailableAt = styled.div`
  color: #4c545a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.14px;

  ${mediaMdMax} {
    .isFWC & {
      font-size: 12px;
    }
  }
`;

const CarBoxEquipments = styled.ul`
  list-style: none;
  gap: ${resolveThemeValue(tokens.spacing.spacingS)};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

const CarBoxEquipmentsItem = styled.li`
  display: flex;
  padding: ${resolveThemeValue(tokens.spacing.spacingXs)} ${resolveThemeValue(tokens.spacing.spacingS)};
  align-items: center;
  gap: ${resolveThemeValue(tokens.spacing.spacingXs)};
  border-radius: 8px;
  border: 1px solid ${resolveThemeValue(tokens.color.border.borderLight)};

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.12px;
  color: #001629;
`;

const Icon = styled.i`
  font-size: 1.5rem !important;

  &:before {
    color: #001629 !important;
  }

  ${mediaSmMax} {
    font-size: 20px !important;
  }
`;
const CarBoxEquipmentsItemCount = styled.span`
  padding-left: 4px;
`;

const CarBoxPrice = styled.h4`
  margin: 0;

  color: ${resolveThemeValue(tokens.color.text.textSecondary)};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.14px;

  &[data-small-price='true'] {
    font-size: 12px !important;
  }

  ${mediaMdMax} {
    .isFWC & {
      font-size: 12px;
    }
  }

  span.price {
    color: #001629;
  }

  span.bigPrice {
    font-weight: 600;
    font-size: 14px;
  }
`;

const CarBoxContentBody = styled.div`
  &:empty {
    border-bottom: 0px;
    padding: 0px;
  }
`;

const PriceWrap = styled(Flex)`
  align-items: baseline;
  height: fit-content;
`;

const FromPricing = styled.div`
  justify-content: space-between;
  justify-items: start;
  display: flex;

  ${mediaMdMin} {
    justify-content: end;
    align-items: baseline;
    gap: 5px;
  }

  span.from {
    color: ${resolveThemeValue(tokens.color.text.textSecondary)};
    ${mediaMdMin} {
      color: initial;
      ${staticHeadlineTextStyles('h4')};
    }
  }

  span.day {
    color: ${resolveThemeValue(tokens.color.text.textSecondary)};
    font-size: 14px;
    padding-left: 5px;
    font-weight: 400;
  }

  span.price {
    ${staticHeadlineTextStyles('h4')};
  }
`;

const CarBoxPriceSection = styled.div<{ forceVertical: boolean }>`
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding-bottom: ${resolveThemeValue(tokens.spacing.spacingM)};

  ${mediaMdMax} {
    flex-direction: column;
    text-align: right;
    justify-content: flex-end;
    padding-bottom: 0;
    margin-left: auto;
    padding-bottom: 10px;
    &[data-has-date='true'] {
      flex-direction: row;

      justify-content: space-between;
    }
  }

  ${({ forceVertical }) =>
    !forceVertical &&
    css`
      text-align: right;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 0;
      margin-left: auto;
    `}
`;

const CarBoxCTASection = styled.div`
  margin-top: auto;
  display: flex;

  gap: 12px;
  width: 100%;

  ${mediaSmMin} {
    flex-direction: column;
  }

  button,
  a {
    width: 100%;
  }
`;
