import {
  AddBookingIntentCommand,
  BookingIntentAction,
  ListingRentalCalendarPriceCalculateQueryResult,
} from '~/libs/api';
import dayjs, { Dayjs } from 'dayjs';
import { createBookingIntent, createBookingPreCheckoutIntent } from '~/src/api/booking';
import { trackAddToCartDataLayer, tryCallDataLayerEvent } from '~/src/utils/dataLayer';
import { trackBookingWidgetCTAClick, trackSuccessfullySendBookingForm, tryCall } from '~/src/utils/analytics';
import { goToBookingPage } from '~/src/utils/query';
import { setCalculationError } from '~/src/redux/actions/calculation';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '~/src/redux/store';
import { calculationSelector } from '~/src/redux/selectors/calculation';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { settingsSelector } from 'features/redux/selectors/settings';
import { userSelector } from '~/src/redux/selectors/user';
import { Dispatch, SetStateAction } from 'react';
import { useCodebase } from 'features/hooks/useCodebase';
import { useLocalizedRouter } from '~/src/components/LocalizedLink';
import { routes } from '~/src/utils/routing';
import { setBookingDataLoading } from '~/src/redux/actions/booking';
import { hideDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { screenSelector } from 'features/redux/selectors/screen';
import { CalculationReduxType } from '~/src/redux/reducers/calculation';

type BookingDatesObject = {
  dateFrom: Dayjs;
  dateTo: Dayjs;
};

type SendBookingIntentOptions = {
  calculationData?: ListingRentalCalendarPriceCalculateQueryResult;
  bookingDates?: BookingDatesObject;
  expectedTotal?: number;
  ecommerceArea?: string;
};

export const useSendBookingDataIntent = (enableTracking = true) => {
  const dispatch = useDispatch();
  const calculation = useTypedSelector(calculationSelector);
  const searchInfo = useTypedSelector(searchInfoSelector);
  const user = useTypedSelector(userSelector);
  const screen = useTypedSelector(screenSelector);
  const isFWC = useCodebase('fwc');
  const router = useLocalizedRouter();

  return async (
    carData: any,
    instantBookable: boolean,
    setCreatingBookingForLoggedUser?: Dispatch<SetStateAction<boolean>>,
    { calculationData, bookingDates, expectedTotal, ecommerceArea }: SendBookingIntentOptions = {}
  ) => {
    setCreatingBookingForLoggedUser?.(true);

    const calculationObject: CalculationReduxType = calculationData
      ? {
          data: calculationData,
          loading: false,
          promoCode: null,
          error: false,
        }
      : calculation;

    const gclidFromLS = localStorage.getItem('gclid');
    const startDate = bookingDates?.dateFrom ?? dayjs(searchInfo.selectedDays.startDate);
    const endDate = bookingDates?.dateTo ?? dayjs(searchInfo.selectedDays.endDate);

    const getAction = () => {
      if (isFWC) return BookingIntentAction.Reservation;
      return calculation.data.instantBookable ? BookingIntentAction.Reservation : BookingIntentAction.Request;
    };

    const bookingDataPayload: AddBookingIntentCommand = {
      action: getAction(),
      listingId: carData.id,
      // date YYYY-MM-DD
      start: startDate.format('YYYY-MM-DD'),
      // date YYYY-MM-DD
      end: endDate.format('YYYY-MM-DD'),
      passengerCount: 1,
      expectedTotal: expectedTotal || calculation?.data?.originalTotal?.total,
      ...(calculation?.data?.rentalPromoCodeIsValid && {
        promoCode: calculation?.promoCode,
      }),
      googleClickId: gclidFromLS ? JSON.parse(gclidFromLS)?.value : undefined,
    };
    try {
      if (user.isAuthenticated) {
        createBookingIntent(bookingDataPayload)
          .then((response: any) => {
            if (String(response.status).startsWith('2')) {
              if (window?.dataLayer) {
                dispatch(setBookingDataLoading(true));
                if (enableTracking) {
                  tryCallDataLayerEvent(() => {
                    trackAddToCartDataLayer(
                      calculationObject,
                      carData,
                      response.data.referenceCode,
                      ecommerceArea ?? 'product detail'
                    );
                  });

                  if (!calculationData) {
                    tryCall(() => trackBookingWidgetCTAClick(carData));
                    tryCall(() =>
                      trackSuccessfullySendBookingForm(
                        instantBookable,
                        carData,
                        calculation,
                        response.data.referenceCode,
                        user.customerData
                      )
                    );
                  }
                }

                router.localizedPush(routes.bookingDetail, {
                  hrefParams: {
                    id: response.data.referenceCode,
                  },
                });
                hideDesktopOrPhoneModal(screen, dispatch);
                // goToBookingPage(response.data.referenceCode, settings);
                if (response.data?.openCheckoutToken)
                  localStorage.setItem('openCheckoutToken', response.data.openCheckoutToken);
                else localStorage.removeItem('openCheckoutToken');

                // tryCallDataLayerEvent(() => {
                //   tryCallBookingCreatedDataLayer(calculation, carData, response.data, () => {
                //     goToBookingPage(response.data.referenceCode, settings);
                //     if (response.data?.openCheckoutToken) localStorage.setItem('openCheckoutToken', response.data.openCheckoutToken);
                //     else localStorage.removeItem('openCheckoutToken');
                //   });
                // });
              } else {
                dispatch(setBookingDataLoading(true));
                router.localizedPush(routes.bookingDetail, {
                  hrefParams: {
                    id: response.data.referenceCode,
                  },
                });
                hideDesktopOrPhoneModal(screen, dispatch);
              }
            } else {
              dispatch(setCalculationError(true));
            }
          })
          .catch((e) => {
            setCreatingBookingForLoggedUser?.(false);
            const customError = e?.response?.data?.errors?.[0]?.message;
            if (customError) {
              dispatch(setCalculationError(customError));
            } else {
              dispatch(setCalculationError(true));
            }
          });
      } else {
        createBookingPreCheckoutIntent(bookingDataPayload)
          .then((response) => {
            if (String(response.status).startsWith('2')) {
              if (window?.dataLayer) {
                dispatch(setBookingDataLoading(true));
                // tryCall(() => trackSuccessfullySendBookingForm(instantBookable, carData, calculation, response.data.referenceCode));
                if (enableTracking) {
                  if (!calculationData) {
                    tryCall(() => trackBookingWidgetCTAClick(carData));
                  }
                  tryCallDataLayerEvent(() => {
                    trackAddToCartDataLayer(
                      calculationObject,
                      carData,
                      response.data.referenceCode,
                      ecommerceArea ?? 'product detail'
                    );
                  });
                }

                router.localizedPush(routes.bookingDetail, {
                  hrefParams: {
                    id: response.data.referenceCode,
                  },
                });
                hideDesktopOrPhoneModal(screen, dispatch);

                if (response.data?.openCheckoutToken)
                  localStorage.setItem('openCheckoutToken', response.data.openCheckoutToken);
                else localStorage.removeItem('openCheckoutToken');

                // tryCallBookingCreatedDataLayer(calculation, carData, response.data, () => {
                //   goToBookingPage(response.data.referenceCode, settings);
                //   if (response.data?.openCheckoutToken) localStorage.setItem('openCheckoutToken', response.data.openCheckoutToken);
                //   else localStorage.removeItem('openCheckoutToken');
                // });
              } else {
                dispatch(setBookingDataLoading(true));
                router.localizedPush(routes.bookingDetail, {
                  hrefParams: {
                    id: response.data.referenceCode,
                  },
                });
                hideDesktopOrPhoneModal(screen, dispatch);
              }
            } else {
              dispatch(setCalculationError(true));
            }
          })
          .catch((e) => {
            setCreatingBookingForLoggedUser?.(false);
            const customError = e?.response?.data?.errors?.[0]?.message;
            if (customError) {
              dispatch(setCalculationError(customError));
            } else {
              dispatch(setCalculationError(true));
            }
          });
      }
    } catch (e) {
      console.error(e);
      setCreatingBookingForLoggedUser?.(false);
      const customError = e?.response?.data?.errors?.[0]?.message;
      if (customError) {
        dispatch(setCalculationError(customError));
      } else {
        dispatch(setCalculationError(true));
      }
    }
  };
};
